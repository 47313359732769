<template>
  <v-dialog v-model="activate" width="491px">
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"
    /></template>
    <v-card>
      <CardHeader>
        <template slot="left">
          <div class="pa-5 font-16px ">
            {{ $t("page_mailing_create_title") }}
          </div>
        </template>
      </CardHeader>
      <v-form class="mail-users">
        <div
          v-for="(user, index) in users"
          :key="index"
          class="d-flex flex-column align-end "
        >
          <div class="d-flex align-center pl-5 pt-4 pr-12">
            <!-- name -->
            <div class="font-12px text-6b6b7b pb-2 pr-6">
              {{ $t("page_mailing_label_name") }}
            </div>
            <div class="input-field">
              <v-text-field
                class="font-12px"
                v-model="user.name"
              ></v-text-field>
            </div>
          </div>
          <!-- Email Address-->
          <div class="d-flex align-center pr-12">
            <div class="font-12px text-6b6b7b pb-2 pr-6">
              {{ $t("page_mailing_label_email") }}
            </div>
            <div class="input-field">
              <v-text-field
                class="font-12px"
                v-model="user.email_address"
                type="email"
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center align-center pr-2">
          <v-icon v-ripple class="mouse-pointer" @click="addMore"
            >$addMailUser</v-icon
          >
        </div>
      </v-form>
      <v-card-actions class="pt-5 pb-6">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 text-light-grey"
          @click="activate = false"
          height="31px"
          depressed
          color="transparent"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="ml-2"
          width="101px"
          height="31px"
          color="primary"
          @click="save"
          >{{ $t("page_mailing_label_addition") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
export default {
  components: {
    CardHeader
  },
  data() {
    return {
      activate: false,
      users: [
        {
          name: "",
          email: ""
        }
      ]
    };
  },
  watch: {
    activate: function(val) {
      if (!val) {
        this.users = [
          {
            name: "",
            email_address: ""
          }
        ];
      }
    }
  },
  methods: {
    save() {
      let counter = 0;
      this.users.forEach(user => {
        if (user.email_address === "" || user.name === "") {
          counter++;
        }
      });
      if (counter === 0) {
        this.$store
          .dispatch("CREATE_MAILING_USER", {
            shop_id: this.$route.params.shop_id,
            mailing_list: this.users
          })
          .then(() => {
            this.$emit("reload-table");
            this.activate = false;
            this.showSaveMessage();
          });
      }
    },
    addMore() {
      this.users.push({
        name: "",
        email_address: ""
      });
    },
    showSaveMessage() {
      this.$store.dispatch("ALERT", {
        show: true,
        text: this.$t("page_mail_save_details_message")
      });
    }
  }
};
</script>
<style lang="scss" src="../style.scss" scoped></style>
