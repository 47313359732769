var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "491px"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true),
    model: {
      value: _vm.activate,
      callback: function callback($$v) {
        _vm.activate = $$v;
      },
      expression: "activate"
    }
  }, [_c('v-card', [_c('CardHeader', [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: "pa-5 font-16px "
  }, [_vm._v(" " + _vm._s(_vm.$t("page_mailing_create_title")) + " ")])])], 2), _c('v-form', {
    staticClass: "mail-users"
  }, [_vm._l(_vm.users, function (user, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex flex-column align-end "
    }, [_c('div', {
      staticClass: "d-flex align-center pl-5 pt-4 pr-12"
    }, [_c('div', {
      staticClass: "font-12px text-6b6b7b pb-2 pr-6"
    }, [_vm._v(" " + _vm._s(_vm.$t("page_mailing_label_name")) + " ")]), _c('div', {
      staticClass: "input-field"
    }, [_c('v-text-field', {
      staticClass: "font-12px",
      model: {
        value: user.name,
        callback: function callback($$v) {
          _vm.$set(user, "name", $$v);
        },
        expression: "user.name"
      }
    })], 1)]), _c('div', {
      staticClass: "d-flex align-center pr-12"
    }, [_c('div', {
      staticClass: "font-12px text-6b6b7b pb-2 pr-6"
    }, [_vm._v(" " + _vm._s(_vm.$t("page_mailing_label_email")) + " ")]), _c('div', {
      staticClass: "input-field"
    }, [_c('v-text-field', {
      staticClass: "font-12px",
      attrs: {
        "type": "email"
      },
      model: {
        value: user.email_address,
        callback: function callback($$v) {
          _vm.$set(user, "email_address", $$v);
        },
        expression: "user.email_address"
      }
    })], 1)])]);
  }), _c('div', {
    staticClass: "d-flex justify-center align-center pr-2"
  }, [_c('v-icon', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "mouse-pointer",
    on: {
      "click": _vm.addMore
    }
  }, [_vm._v("$addMailUser")])], 1)], 2), _c('v-card-actions', {
    staticClass: "pt-5 pb-6"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-2 text-light-grey",
    attrs: {
      "height": "31px",
      "depressed": "",
      "color": "transparent"
    },
    on: {
      "click": function click($event) {
        _vm.activate = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "101px",
      "height": "31px",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t("page_mailing_label_addition")))]), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }