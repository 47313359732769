var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('Parent'), _c('Table', {
    staticClass: "mt-3",
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.getMailingList,
      "number-of-pages": _vm.getMailingListPagination ? _vm.getMailingListPagination.total_pages : 0,
      "total-records": _vm.getMailingListPagination ? _vm.getMailingListPagination.records_total : 0
    },
    on: {
      "update:options": _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('CardHeader', [_c('template', {
          slot: "left"
        }, [_c('div', {
          staticClass: "ml-6"
        }, [_c('v-card', {
          attrs: {
            "color": "#525252",
            "flat": "",
            "height": "29px",
            "width": "195px"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('v-icon', {
          attrs: {
            "size": "17"
          }
        }, [_vm._v("$mailingWhite")]), _c('div', {
          staticClass: "font-18px white--text pl-2"
        }, [_vm._v(" " + _vm._s(_vm.$t("page_mailing_title")) + " ")])], 1)])], 1)]), _c('template', {
          slot: "right"
        }, [_c('div', {
          staticClass: "d-flex justify-center align-baseline pr-9"
        }, [_c('div', {
          staticClass: "search mr-4 py-5"
        }, [_c('TableSearch', {
          attrs: {
            "search": _vm.searchTable
          },
          on: {
            "update:search": [function ($event) {
              _vm.searchTable = $event;
            }, _vm.fetchData]
          }
        })], 1), _c('CreateDialog', {
          on: {
            "reload-table": _vm.fetchData
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "font-14px",
                attrs: {
                  "color": "primary",
                  "text": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "color": "primary"
                }
              }, [_vm._v("mdi-plus")]), _vm._v(" 新規登録 ")], 1)];
            }
          }])
        })], 1)])], 2)];
      },
      proxy: true
    }, {
      key: "item.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.created_at, "YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "item.edit",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('EditDialog', {
          attrs: {
            "item": item
          },
          on: {
            "reload-table": _vm.fetchData
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-9"
              }, 'v-icon', attrs, false), on), [_vm._v("$edit")])];
            }
          }], null, true)
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }