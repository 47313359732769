<template>
  <div class="">
    <Parent />
    <Table
      class="mt-3"
      :loading="loading"
      :headers="headers"
      :items="getMailingList"
      :number-of-pages="
        getMailingListPagination ? getMailingListPagination.total_pages : 0
      "
      :total-records="
        getMailingListPagination ? getMailingListPagination.records_total : 0
      "
      @update:options="fetchData"
    >
      <template v-slot:top>
        <CardHeader>
          <template slot="left">
            <div class="ml-6">
              <v-card color="#525252" flat height="29px" width="195px">
                <div class="d-flex justify-center align-center">
                  <v-icon size="17">$mailingWhite</v-icon>
                  <div class="font-18px white--text pl-2">
                    {{ $t("page_mailing_title") }}
                  </div>
                </div>
              </v-card>
            </div>
          </template>
          <template slot="right">
            <div class="d-flex justify-center align-baseline pr-9">
              <div class="search mr-4 py-5">
                <TableSearch
                  :search.sync="searchTable"
                  @update:search="fetchData"
                ></TableSearch>
              </div>
              <CreateDialog @reload-table="fetchData">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="font-14px"
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-plus</v-icon>
                    新規登録
                  </v-btn>
                </template>
              </CreateDialog>
            </div>
          </template>
        </CardHeader>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate("YYYY-MM-DD") }}
      </template>
      <template v-slot:item.edit="{ item }">
        <EditDialog :item="item" @reload-table="fetchData">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-9" v-on="on" v-bind="attrs">$edit</v-icon>
          </template>
        </EditDialog>
      </template>
    </Table>
  </div>
</template>
<script>
import Parent from "@/views/admin/Store/Index.vue";
import Table from "@/components/admin/partials/Table/Table";
import CardHeader from "@/components/admin/ui/CardHeader";
import CreateDialog from "@/views/admin/Mailing/partials/CreateDialog";
import EditDialog from "@/views/admin/Mailing/partials/EditDialog";
import TableSearch from "@/components/admin/partials/Search/TableSearch";
import { mapGetters } from "vuex";
export default {
  components: {
    Parent,
    Table,
    CardHeader,
    CreateDialog,
    EditDialog,
    TableSearch
  },
  data() {
    return {
      searchTable: "",
      showDelete: false,
      loading: true,
      headers: [
        {
          text: "No.",
          sortable: false,
          align: "center",
          value: "id",
          width: "120"
        },
        {
          text: this.$t("page_mailing_table_name"),
          sortable: false,
          align: "center",
          value: "name",
          width: "120"
        },
        {
          text: this.$t("page_mailing_table_email"),
          sortable: false,
          align: "center",
          value: "email_address",
          width: "750"
        },
        {
          text: this.$t("page_mailing_table_reisteration"),
          align: "center",
          value: "created_at",
          width: "300"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "edit"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getMailingList", "getMailingListPagination"])
  },
  methods: {
    async fetchData(e = undefined) {
      this.loading = true;
      let params = {};
      params.shop_id = this.$route.params.shop_id;
      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.order = e.sortDesc[0] ? "desc" : "asc";
        params.sort = e.sortBy[0];
      } else {
        params.sort = "created_at";
        params.order = "desc";
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      }

      if (this.searchTable) {
        params.search = this.searchTable;
      }

      await this.$store.dispatch("FETCH_MAILING_LIST", params).then(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>

