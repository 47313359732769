<template>
  <v-dialog v-model="activate" width="491px">
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"
    /></template>
    <v-card>
      <CardHeader>
        <template slot="left">
          <div class="pa-5 font-16px ">
            {{ $t("page_mailing_list_edit") }}
          </div>
        </template>
      </CardHeader>
      <v-form class=" ">
        <div class="d-flex flex-column align-end pr-9">
          <div
            class="d-flex align-center pt-4"
            v-for="(field, index) in fields"
            :key="index"
          >
            <!-- name -->
            <div class="font-12px text-6b6b7b pb-2 pr-6">
              {{ field.label }}
            </div>
            <div class="input-field">
              <v-text-field
                class="font-14px text-6b6b7b"
                v-model="field.value"
                :type="field.type"
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-form>
      <v-card-actions class="mt-1 pb-6">
        <div
          class="pl-4 text-red mouse-pointer font-weight-bold"
          @click="deleteMail"
        >
          {{ $t("delete") }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          height="31px"
          class="mx-2 text-light-grey"
          @click="activate = !activate"
          depressed
          color="transparent"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mx-2"
          height="31px"
          width="101px"
          color="primary"
          @click="updateMailAddress"
          >保存</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
export default {
  components: {
    CardHeader
  },
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      activate: false,
      fields: {
        name: {
          value: "",
          type: "text",
          label: this.$t("page_mailing_label_name")
        },
        email_address: {
          value: "",
          type: "email",
          label: this.$t("page_mailing_label_email")
        }
      }
    };
  },
  watch: {
    activate: function(val) {
      if (val) {
        for (let field in this.fields) {
          this.fields[field].value = this.item[field];
        }
      }
    }
  },
  methods: {
    editSave() {
      this.$store.dispatch("ALERT", {
        show: true,
        text: this.$t("page_mail_edit_details_message")
      });
    },
    deleteItem() {
      this.$store.dispatch("ALERT", {
        show: true,
        text: this.$t("page_email_edit_delete_message")
      });
    },
    async deleteMail() {
      this.activate = false;
      const response = await this.$root.confirm.open(
        this.$t("page_mailing_list_delete_message") +
          "\n" +
          this.$t("delete_confirmation_alert"),
        "Are you sure?",
        { color: "red" }
      );
      if (response) {
        this.$store
          .dispatch("DELETE_MAILING_USER", {
            shop_id: this.item.shop_id,
            id: this.item.id
          })
          .then(() => {
            this.$emit("reload-table");
            this.deleteItem();
          });
      }
    },
    updateMailAddress() {
      let counter = 0;
      let updated = {};
      for (let field in this.fields) {
        if (this.fields[field].value === "") {
          ++counter;
        } else {
          updated[field] = this.fields[field].value;
        }
      }
      if (counter === 0) {
        this.$store
          .dispatch("UPDATE_MAILING_USER", {
            shop_id: this.item.shop_id,
            id: this.item.id,
            ...updated
          })
          .then(() => {
            this.activate = false;
            this.$emit("reload-table");
            this.editSave();
          });
      }
    }
  }
};
</script>
<style lang="scss" src="../style.scss" scoped></style>
